import { Component, OnDestroy, OnInit, ViewChild, Input, Output, HostListener, AfterViewInit, OnChanges,SimpleChanges } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseService } from 'src/app/shared/services/API/services/purchase/purchase.service';
import { ListPurchaseReponse } from 'src/app/shared/services/API/responses/purchase/list-purchase.response';
import { ModalDeleteComponent } from 'src/app/supplier/pages/order-details-modal/modal-delete/modal-delete.component';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';
import { PurchaseHistoryService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history.service';
import { GetPurchaseHistoryResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-history.response';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { PurchaseHistoryStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/purchase-history.struct';



@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.css']
})
export class PurchaseHistoryComponent implements OnInit{

  constructor(private dialogRef: MatDialog,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private supplierService: SupplierService,
    private purchaseService: PurchaseService,
    private purchaseHistoryService: PurchaseHistoryService,) {

      this.onResize();
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public setDetails: Boolean = false;
  public getPurchase: any = [];
  public isLoading: boolean = false;
  public idPurchase: number;
  public idUser: number;
  public alertService: AlertService;
  public isDetails: boolean[] = [];
  public matPaginatorModule: MatPaginatorModule;
  public listPurchaseReponse: ListPurchaseReponse;
  public getPurchaseHistoryResponse: GetPurchaseHistoryResponse = null;
  public getSupplierResponse: GetSupplierResponse;
  public matProgressSpinnerModule:MatProgressSpinnerModule;
  public matDatepickerModule:MatDatepickerModule;
  public matFormFieldModule:MatFormFieldModule;
  public matInputModule:MatInputModule;
  public length: any;
  public data: any;
  public buyOrder: any;
  public totalPrice: any;
  public pageSize: number = 5;
  public idBuyOrder: number;
  public model: FormGroup;
  public screenWidth: number;
  public modalAberto = false;
  public loading: boolean = false;

  ngOnInit(): void {

    this.model = this.formBuilder.group({
      start: [''],
      end: [''],
      text:['']
    });

    this.populatePage();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<PurchaseHistoryStruct>([]);
  slicedData: PurchaseHistoryStruct[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }


  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.paginator.page.subscribe((pageEvent: PageEvent) => {
  //     this.sliceData(pageEvent);
  //   });
  // }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.dataSource && !changes.dataSource.firstChange) {
  //     this.dataSource.paginator = this.paginator;
  //   }
  // }

  sliceData(pageEvent: PageEvent) {
    const startIndex = pageEvent.pageIndex * pageEvent.pageSize;
    const endIndex = startIndex + pageEvent.pageSize;
    this.slicedData = this.dataSource.data.slice(startIndex, endIndex);
  }

  clearFilter(){

  }

  populatePage() {
    this.loading = true;

    if (this.purchaseHistoryService) {
      this.purchaseHistoryService.GetAllPurchases(0, 112).subscribe({
        next: (getPurchaseHistoryResponse: GetPurchaseHistoryResponse) => {
          if (getPurchaseHistoryResponse.isError) {
            this.alertService.show('Erro', getPurchaseHistoryResponse.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
          this.getPurchaseHistoryResponse = getPurchaseHistoryResponse;
          this.buyOrder = this.getPurchaseHistoryResponse.listPurchase
          this.totalPrice = this.buyOrder.listBuyOrderPart;
          this.idBuyOrder = this.buyOrder.idPurchase
          this.data = this.getPurchaseHistoryResponse.listPurchase
          this.length = this.getPurchaseHistoryResponse.listPurchase.length;
          this.modalAberto = true;         
          this.dataSource.data = getPurchaseHistoryResponse.listPurchase; 
          this.slicedData = this.dataSource.data.slice(0, this.pageSize);
        }
      });
    } else {
      console.error('listPurchaseService is undefined');
    }
  }

  toggleDetails(item: any): void {
    item.isDetails = !item.isDetails;
    item.idPurchase;
  }

  setIsDetails() {
    if (this.setDetails == true) {
      return this.setDetails = false
    }
    else {
      return this.setDetails = true
    }
  }

}
