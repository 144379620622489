import { Component, OnDestroy, OnInit, Inject, HostListener, Output, EventEmitter } from '@angular/core';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { FormsModule, UntypedFormBuilder, FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { PurchaseHistoryService } from '../../../shared/services/API/services/orchestrator-purchase/purchase-history.service';
import { PurchaseSupplierStruct } from '../../../shared/services/API/structs/orchestrator-purchase/purchase-supplier.struct';
import { ListSupplierOrdersResponse } from '../../../shared/services/API/responses/orchestrator-purchase/list-supplier-orders.response';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SupplierOrdersDetailResponse } from '../../../shared/services/API/responses/orchestrator-purchase/supplier-orders-detail.response';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ButtonDeliveryManArrivedComponent } from './buttons/button-delivery-man-arrived/button-delivery-man-arrived.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { copyFileSync } from 'fs';

@Component({
  selector: 'app-order-details-modal',
  templateUrl: './order-details-modal.component.html',
  styleUrls: ['./order-details-modal.component.css']
})
export class OrderDetailsModalComponent implements OnInit, OnDestroy {

  public supplierOrdersDetailResponse: SupplierOrdersDetailResponse;
  public listSupplierOrdersResponse:ListSupplierOrdersResponse;
  public isLoading: boolean;
  public idBuyOrder: number = this.data.idBuyOrder;
  public listProductIdOrder:any;
  public price: number;
  public totalPrice: number = 0;
  public quantity: number;
  public priceUnit: number;
  public matProgressSpinnerModule:MatProgressSpinnerModule;
  public status: string;
  public entrega: string;
  public street: string;
  public number: string;
  public state: string;
  public zipcode: string;
  public city: string;
  public neighborhood: string;
  public model: FormGroup;
  public form: FormGroup;
  public listAdress: any;
  public idBuyOrderStatus: number;
  public idSupplier: number;
  public oficina: string;
  public populateInterval;
  public lalamoveLink: SafeResourceUrl;
  public loadedData: boolean = false;
  public loading: boolean = false;
  public modalAberto = false;
  public count = 0;
  public screenWidth: number;

  constructor(private dialogRef: MatDialog,
    private purchaseHistoryService:PurchaseHistoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService:AlertService,
    private sanitizer: DomSanitizer){
      this.onResize();
  }

  ngOnInit(): void {
    this.populatePage();
    this.populateInterval = setInterval(() => {
      this.populatePage();
    }, 30000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnDestroy(){
    clearInterval(this.populateInterval);
  }



  populatePage(){
    this.loading = true;
    const idBuyOrder: number = this.data.idBuyOrder; 

    if (this.purchaseHistoryService) {
      this.purchaseHistoryService.Get(idBuyOrder).subscribe({
        next: (purchaseHistoryService: SupplierOrdersDetailResponse) =>{
          if(purchaseHistoryService.isError){
            this.alertService.show('Erro', purchaseHistoryService.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
          this.lalamoveLink = this.sanitizer.bypassSecurityTrustResourceUrl(purchaseHistoryService.lalamoveLink);
          this.supplierOrdersDetailResponse = purchaseHistoryService;
          this.listProductIdOrder = this.supplierOrdersDetailResponse.listPartProductStruct;
          this.listAdress = this.supplierOrdersDetailResponse.branchDeliveryAddress;
          this.status = this.supplierOrdersDetailResponse.buyOrderStatusName;
          this.idBuyOrderStatus = this.supplierOrdersDetailResponse.idBuyOrderStatus;
          this.idSupplier = this.supplierOrdersDetailResponse.idSupplier;
          this.entrega = this.supplierOrdersDetailResponse.deliveryTypeName;
          this.oficina = this.supplierOrdersDetailResponse.branchName;
          this.street = this.listAdress.street;
          this.neighborhood = this.listAdress.neighborhood;
          this.city = this.listAdress.city;
          this.number = this.listAdress.number;
          this.state = this.listAdress.state;
          this.zipcode = this.listAdress.zipcode;

          this.totalPrice = this.listProductIdOrder.reduce((total, item) => {
            return total = item.price * item.quantity;
          }, this.totalPrice);
          this.loadedData = true; 
          this.modalAberto = true;
        },
        error: (error) => {
        },
        complete: () => {
          this.loading = false;
        }
      });
    } else {
    }
  }

  getStatusColor(status: number): string {
    switch (status) {
      case 1:
        return 'blue';
      case 2:
          return 'yellow';
      case 3:
        return 'blue';
      case 4:
        return 'green';
      case 5:
        return 'green';
      case 6:
        return 'red';
      case 7:
          return 'red';
      case 8:
          return 'red';
      case 9:
          return 'orange';
      case 10:
        return 'red';
      case 11:
        return 'red';
      default:
    }
  }
}
