import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { SearchPartsRequest } from 'src/app/shared/services/API/requests/autoparts/search-parts.request';
import { SearchPartsResponse } from 'src/app/shared/services/API/responses/autoparts/Parts/search-parts.response';
import { PartsService } from 'src/app/shared/services/API/services/autoparts/parts.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SearchShoppingCarItemStruct, SearchShoppingCartService, SearchShoppingCartStruct } from './search-shopping-cart/search-shopping-cart.service';
import { PartProductStruct } from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GetInfosService } from 'src/app/shared/services/API/services/autoparts/get-infos.service';
import { GetInfosResponse } from 'src/app/shared/services/API/responses/autoparts/Vehicle/get-infos.response';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { SearchPlateRequest } from 'src/app/shared/services/API/requests/autoparts/search-plate.request';
import { VehicleService } from 'src/app/shared/services/API/services/autoparts/vehicle.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private partsService: PartsService,
    private alertService: AlertService,
    private searchShoppingCartService:SearchShoppingCartService,
    private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private getInfosService:GetInfosService){

      this.onResize();

  }

  public description = '';
  public plateNumber = '';
  public idBrand:number;

  public idVehicleModel:number;
  public idBrandPlate: string;
  public descriptionPlate: string;
  public idVehicleModelPlate: number;
  public idVersionPlate: number

  public year:number;
  public idVersion:number;

  public isLoading = false;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;

  public openFilter = false;

  public searchPartsResponse:SearchPartsResponse = null;


  public searchShoppingCartStuct:SearchShoppingCartStruct = null;

  public modelSearch: FormGroup;
  public getInfosResponse:GetInfosResponse;

  public idServiceOrder:number;
  public idQuoteService:number;

  public minPartsCount = 8;
  public skip = 0;
  public take =  100;
  public partsCount = 0;
  public collectedParts: PartProductStruct[] = [];
  public filteredParts: PartProductStruct[] = [];
  public hasItemsInCart: boolean = false;
  public isFilterOpen = false;
  public screenWidth: number;


  @ViewChild('menu') menu: MenuComponent;

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.paramMap.get('description'))
      this.description = this.activatedRoute.snapshot.paramMap.get('description');

    if (this.activatedRoute.snapshot.paramMap.get('plateNumber'))
      this.plateNumber = this.activatedRoute.snapshot.paramMap.get('plateNumber');

    if (this.activatedRoute.snapshot.paramMap.get('idBrand'))
      this.idBrand = parseInt(this.activatedRoute.snapshot.paramMap.get('idBrand'));

    if (this.activatedRoute.snapshot.paramMap.get('idVehicleModel'))
      this.idVehicleModel = parseInt(this.activatedRoute.snapshot.paramMap.get('idVehicleModel'));

    if (this.activatedRoute.snapshot.paramMap.get('year'))
      this.year = parseInt(this.activatedRoute.snapshot.paramMap.get('year'));

    if (this.activatedRoute.snapshot.paramMap.get('idVersion'))
      this.idVersion = parseInt(this.activatedRoute.snapshot.paramMap.get('idVersion'));

      this.activatedRoute.queryParams.subscribe(params => {
        this.idServiceOrder = params.idServiceOrder;
        this.idQuoteService = params.idQuoteService;

        this.secundaryHeader();
      });

      this.modelSearch = this.formBuilder.group({
        description: [this.description, [Validators.required]],
      });


    if(this.plateNumber != ''){
      this.searchPlate()
    }else if (this.year != null){
      this.searchByVehicle();
    }else{
      this.search(false)
    }

    this.getCartList()

  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  distributeParts2(parts : PartProductStruct[]) {
    const newCollectedParts: PartProductStruct[] = [];
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].supplierInternalInfo.length <= 1) {
        const newPartProduct = JSON.parse(JSON.stringify(parts[i]));
        newPartProduct.price = parts[i].priceInvoice;
        newPartProduct.quantity = parts[i].totalQuantity;
        newPartProduct.idBusinessSupplier = parts[i].idBusinessSupplier;
        // newPartProduct.idBranch = parts[i].supplierInternalInfo[0].supplierBranchId;
        newPartProduct.supplierName = parts[i].supplierInternalInfo[0].supplierName;
        newCollectedParts.push(newPartProduct);
      } else {
        for (let j = 0; j < parts[i].supplierInternalInfo.length; j++) {
          const newPartProduct = JSON.parse(JSON.stringify(parts[i]));
          newPartProduct.price = parts[i].priceInvoice;
          newPartProduct.quantity = parts[i].totalQuantity;
          newPartProduct.idBusinessSupplier = parts[i].idBusinessSupplier;
          // newPartProduct.idBranch = parts[i].supplierInternalInfo[j].supplierBranchId;
          newPartProduct.supplierName = parts[i].supplierInternalInfo[j].supplierName;
          newCollectedParts.push(newPartProduct);
        }
      }
    }
    // this.collectedParts = [];
   return newCollectedParts;
  }

  searchHeard(){
    let nextPage = false
    if(this.plateNumber != ''){
      this.searchPlate(nextPage)
    }else{
      this.search(nextPage);
    }
  }

  nextPageSearch(){
    if(this.plateNumber != ''){
      let nextPage = true
      this.searchPlate(nextPage)
    }else{
      let nextPage = true
      this.search(nextPage);
    }
  }

  populate(listVersion){
    // this.searchShoppingCartStuct = this.searchShoppingCartService.getParts();

    if(this.idBrand != null){
      this.getInfosService.post(this.idBrand, this.idVehicleModel, this.idVersion).subscribe((getInfosResponse:GetInfosResponse)=>{
        if(getInfosResponse.isError == true){
          this.alertService.show("Erro", getInfosResponse.errorDescription, AlertType.error);
          return;
        }

        this.getInfosResponse = getInfosResponse;
      },
      (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      });
    }else{
      this.getInfosService.post(listVersion[0].idBrand, listVersion[0].idVehicleModel, listVersion[0].idVersion).subscribe((getInfosResponse:GetInfosResponse)=>{
        if(getInfosResponse.isError == true){
          this.alertService.show("Erro", getInfosResponse.errorDescription, AlertType.error);
          return;
        }

        this.getInfosResponse = getInfosResponse;
      },
      (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      });
    }
  }

  searchPlate(nextPage?) {
    const searchPlateRequest: SearchPlateRequest = new SearchPlateRequest();
    searchPlateRequest.plate = this.plateNumber;

    this.vehicleService.searchPlate(searchPlateRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          return;
        }
        let listVersion = response.version;

        if(listVersion != null){
          this.populate(listVersion);
          this.searchByPlate(listVersion, nextPage)
        }
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  clearCollectedParts(): void {
    this.collectedParts = [];
  }

  searchByVehicle(nextPage?){
    const searchPartsRequest:SearchPartsRequest = new SearchPartsRequest();
    let partName = this.modelSearch.get("description").value
    if(partName == "null"){
      partName = null
    }

    searchPartsRequest.partName = partName;
    searchPartsRequest.idBrand = this.idBrand;
    searchPartsRequest.idApplicabilityModel = this.idVehicleModel;
    searchPartsRequest.applicabilityYear = this.year;
    searchPartsRequest.idApplicabilityModelType = this.idVersion;
    searchPartsRequest.idApplicabilityVersion = null;
    searchPartsRequest.idVehicleVersion = null;


    if(this.searchPartsResponse != null) {
      searchPartsRequest.listIdPartBrand = this.searchPartsResponse.listSearchedBrands.filter(c=> c.isVehicleMaker == true).map(c=> c.idBrand);
    }

    this.isLoading = true
    if(nextPage){
      this.skip = this.skip + this.take;
      searchPartsRequest.skip = this.skip;
      searchPartsRequest.take = this.take;
  
      this.partsService.searchParts(searchPartsRequest).subscribe({
        next: (searchPartsResponse: SearchPartsResponse) =>{
          if(searchPartsResponse.isError){
            this.alertService.show('Erro', searchPartsResponse.errorDescription, AlertType.error)
              this.isLoading = false;
              return;
          }
          this.searchPartsResponse = searchPartsResponse
          // let newCollectedParts = []
          // newCollectedParts = (this.distributeParts2(searchPartsResponse.listPartProduct))
          this.collectedParts.push(...searchPartsResponse.listPartProduct)
          this.isLoading = false;
          if(this.collectedParts.length == 0){
            this.alertService.show('Erro', "Peça não encontrada", AlertType.error)
          }
        },
        error: (error) =>{
          this.isLoading = false;
          this.alertService.show("Erro inesperado", error, AlertType.error);
        }
      })
    }else{
      searchPartsRequest.skip = this.skip;
      searchPartsRequest.take = this.take;
  
      this.partsService.searchParts(searchPartsRequest).subscribe({
        next: (searchPartsResponse: SearchPartsResponse) =>{
          if(searchPartsResponse.isError){
            this.alertService.show('Erro', searchPartsResponse.errorDescription, AlertType.error)
            this.isLoading = false;
              return;
          }
          this.searchPartsResponse = searchPartsResponse
          // let newCollectedParts = []
          // newCollectedParts = (this.distributeParts2(searchPartsResponse.listPartProduct))
          this.collectedParts.push(...searchPartsResponse.listPartProduct)
          this.isLoading = false;
          if(this.collectedParts.length == 0){
            this.alertService.show('Erro', "Peça não encontrada", AlertType.error)
          }
        },
        error: (error) =>{
          this.isLoading = false;
          this.alertService.show("Erro inesperado", error, AlertType.error);
        }
      })
    }
  }

  searchByPlate(listVersion, nextPage?){
    const searchPartsRequest:SearchPartsRequest = new SearchPartsRequest();

    searchPartsRequest.partName = this.modelSearch.get("description").value;
    searchPartsRequest.idBrand = null;
    searchPartsRequest.idApplicabilityModel = null;
    searchPartsRequest.applicabilityYear = null;
    searchPartsRequest.idApplicabilityVersion = null;
    searchPartsRequest.idVehicleVersion = listVersion[0].idVersion;

    if(this.searchPartsResponse != null) {
      searchPartsRequest.listIdPartBrand = this.searchPartsResponse.listSearchedBrands.filter(c=> c.isVehicleMaker == true).map(c=> c.idBrand);
    }

    this.isLoading = true
    if(nextPage){
      this.skip = this.skip + this.take;
      searchPartsRequest.skip = this.skip;
      searchPartsRequest.take = this.take;
  
      this.partsService.searchParts(searchPartsRequest).subscribe({
        next: (searchPartsResponse: SearchPartsResponse) =>{
          if(searchPartsResponse.isError){
            this.alertService.show('Erro', searchPartsResponse.errorDescription, AlertType.error)
              this.isLoading = false;
              return;
          }
          this.searchPartsResponse = searchPartsResponse
          // let newCollectedParts = []
          // newCollectedParts = (this.distributeParts2(searchPartsResponse.listPartProduct))
          this.collectedParts.push(...searchPartsResponse.listPartProduct)
          this.isLoading = false;
          if(this.collectedParts.length == 0){
            this.alertService.show('Erro', "Peça não encontrada", AlertType.error)
          }
        },
        error: (error) =>{
          this.isLoading = false;
          this.alertService.show("Erro inesperado", error, AlertType.error);
        }
      })
    }else{
      searchPartsRequest.skip = this.skip;
      searchPartsRequest.take = this.take;
  
      this.partsService.searchParts(searchPartsRequest).subscribe({
        next: (searchPartsResponse: SearchPartsResponse) =>{
          if(searchPartsResponse.isError){
            this.alertService.show('Erro', searchPartsResponse.errorDescription, AlertType.error)
              this.isLoading = false;
              return;
          }
          this.searchPartsResponse = searchPartsResponse
          // let newCollectedParts = []
          // newCollectedParts = (this.distributeParts2(searchPartsResponse.listPartProduct))
          this.collectedParts.push(...searchPartsResponse.listPartProduct)
          this.isLoading = false;
          if(this.collectedParts.length == 0){
            this.alertService.show('Erro', "Peça não encontrada", AlertType.error)
          }
        },
        error: (error) =>{
          this.isLoading = false;
          this.alertService.show("Erro inesperado", error, AlertType.error);
        }
      })
    }
  }

  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  applyFilter(event: { filterCheck: string; isChecked: boolean }) {
    const { filterCheck, isChecked } = event;
    if (isChecked && filterCheck.length > 0) {
      this.filteredParts = this.collectedParts.filter(part => filterCheck.includes(part.brandName));
    } else {
      this.filteredParts = [...this.collectedParts];
    }
  }

  search(nextPage?){
    const searchPartsRequest:SearchPartsRequest = new SearchPartsRequest();

    searchPartsRequest.partName = this.modelSearch.get("description").value;
    searchPartsRequest.idBrand = null;
    searchPartsRequest.idApplicabilityModel = null;
    searchPartsRequest.applicabilityYear = null;
    searchPartsRequest.idApplicabilityVersion = null;
    searchPartsRequest.idVehicleVersion = null;


    if(this.searchPartsResponse != null) {
      searchPartsRequest.listIdPartBrand = this.searchPartsResponse.listSearchedBrands.filter(c=> c.isVehicleMaker == true).map(c=> c.idBrand);
    }

    this.isLoading = true
    if(nextPage){
      this.skip = this.skip + this.take;
      searchPartsRequest.skip = this.skip;
      searchPartsRequest.take = this.take;
  
      this.partsService.searchParts(searchPartsRequest).subscribe({
        next: (searchPartsResponse: SearchPartsResponse) =>{
          if(searchPartsResponse.isError){
            this.alertService.show('Erro', searchPartsResponse.errorDescription, AlertType.error)
              this.isLoading = false;
              return;
          }
          this.searchPartsResponse = searchPartsResponse
          // let newCollectedParts = []
          // newCollectedParts = (this.distributeParts2(searchPartsResponse.listPartProduct))
          this.collectedParts.push(...searchPartsResponse.listPartProduct)
          this.isLoading = false;

          if(this.collectedParts.length == 0){
            this.alertService.show('Erro', "Peça não encontrada", AlertType.error)
          }
        },
        error: (error) =>{
          this.isLoading = false;
          this.alertService.show("Erro inesperado", error, AlertType.error);
        }
      })
    }else{
    searchPartsRequest.skip = this.skip;
    searchPartsRequest.take = this.take;

    this.partsService.searchParts(searchPartsRequest).subscribe({
      next: (searchPartsResponse: SearchPartsResponse) =>{
        if(searchPartsResponse.isError){
          this.alertService.show('Erro', searchPartsResponse.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
        }
        this.searchPartsResponse = searchPartsResponse
        // let newCollectedParts = []
        // newCollectedParts = (this.distributeParts2(searchPartsResponse.listPartProduct))
        this.collectedParts.push(...searchPartsResponse.listPartProduct)
        this.isLoading = false;

        if(this.collectedParts.length == 0){
          this.alertService.show('Erro', "Peça não encontrada", AlertType.error)
        }
      },
      error: (error) =>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    })
    }
  }
  
    clearFilter(){
      // if(this.searchPartsResponse != null && this.searchPartsResponse.listSearchedBrands != null){
      //   this.searchPartsResponse.listSearchedBrands.forEach(item => {
      //     item.isVehicleMaker = false;
      //   });
      // }
  
  
      // // if(this.searchPartsResponse != null && this.searchPartsResponse.listSearchedGroups != null){
      // //   this.searchPartsResponse.listSearchedGroups.forEach(item => {
      // //     item.isChecked = false;
      // //   });
      // // }
  
      // this.search(false);
      this.filteredParts = [...this.collectedParts];
      console.log(this.filteredParts)
    }

  clickFilter(){
    this.openFilter = !this.openFilter;
  }

  clickAddItem(amount:number, partProductStruct:PartProductStruct){

    this.searchShoppingCartStuct = this.searchShoppingCartService.addPart(amount, partProductStruct);
    this.hasItemsInCart = this.searchShoppingCartStuct.items > 0;
  }

  removeItem(idPart){
    this.searchShoppingCartStuct = this.searchShoppingCartService.deleteaddPart(idPart, this.searchShoppingCartStuct);
  }

  clickChangeItem(amount:number, searchShoppingCarItemtStuct:SearchShoppingCarItemStruct){
    this.searchShoppingCartStuct = this.searchShoppingCartService.deleteaddPart(searchShoppingCarItemtStuct.partProductStruct.idPart, this.searchShoppingCartStuct);
    this.searchShoppingCartStuct = this.searchShoppingCartService.addPart(amount, searchShoppingCarItemtStuct.partProductStruct);
  }

  getCartList() {
    this.searchShoppingCartStuct = this.searchShoppingCartService.getParts();
    if(this.searchShoppingCartStuct && this.searchShoppingCartStuct.items > 0){
      return this.hasItemsInCart = true
    }else{
      return this.hasItemsInCart = false
    }
  }

  secundaryHeader(){
    if(this.idServiceOrder != undefined){
      setTimeout(() => {
        this.menu.reloadHeader(this.idServiceOrder);
      }, 1000);
    }
  }
}
