import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BranchDeliveryAddress } from 'src/app/shared/services/API/models/user/branch-delivery-address.model';
import { BranchDeliveryAdressRequest } from 'src/app/shared/services/API/requests/user/branch-delivery-address.request';
import { CreateBranchDeliveryAdressResponse } from 'src/app/shared/services/API/responses/user/create-branch-delivery-address.response';
import { BranchDeliveryAdressService } from 'src/app/shared/services/API/services/user/branch-delivery-address.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Component({
  selector: 'app-delivery-address-modal',
  templateUrl: './delivery-address-modal.component.html',
  styleUrls: ['./delivery-address-modal.component.css']
})
export class DeliveryAddressModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<DeliveryAddressModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private branchDeliveryAdressService: BranchDeliveryAdressService,
    private alertService: AlertService,
  ){
    this.matDialogRef.disableClose = true;
  }

  public model: UntypedFormGroup;
  public isLoading: boolean = false;
  public masks:Masks;
  public isUpdate: boolean = false;
  public address: BranchDeliveryAddress;
  public idBranchDeliveryAddress: number;
  public addressRequest: BranchDeliveryAdressRequest;
  public populatedAddress: string = null;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      isDefault: [false, Validators.required],
    });
    
    this.masks = this.maskService.getMasks();
    this.address = this.data.address;
    if(this.address)
      this.idBranchDeliveryAddress = this.address.idBranchDeliveryAddress
    
    this.isUpdate == this.data.isUpdate;
    
    if(this.idBranchDeliveryAddress != null){
      this.isUpdate = true;
      this.populateData();
    }

    this.addressRequest = new BranchDeliveryAdressRequest();
  }

  getEstablishmentAddress(event){
    // this.model.get('description').setValue(null)
    if(event.address_components.length < 7){
      this.alertService.show("Aviso", "O endereço informado está incompleto. Informe um endereço completo para o cadastro", AlertType.warning);
      return;
    }
      let hasRoomNumber: boolean = false;
    if(event.address_components.length > 7){
      hasRoomNumber = true; 
      this.model.get('description').markAsTouched();
      this.model.get('description').markAsDirty();
      this.model.get('description').setValue(event.address_components[0].long_name);
    }

    this.addressRequest.idBranchDeliveryAdress = this.idBranchDeliveryAddress;
    this.addressRequest.number = event.address_components[hasRoomNumber? 1: 0].long_name
    this.addressRequest.street = event.address_components[hasRoomNumber? 2: 1].long_name
    this.addressRequest.neighborhood = event.address_components[hasRoomNumber? 3: 2].long_name
    this.addressRequest.city = event.address_components[hasRoomNumber? 4: 3].long_name
    this.addressRequest.state = event.address_components[hasRoomNumber? 5: 4].long_name
    this.addressRequest.country = event.address_components[hasRoomNumber? 6: 5].long_name
    this.addressRequest.zipcode = event.address_components[hasRoomNumber? 7: 6].long_name

    var latitude = event.geometry.location.lat();
    var longitude = event.geometry.location.lng(); 
    this.addressRequest.latitude = latitude.toString()
    this.addressRequest.longitude = longitude.toString()
    console.log(this.addressRequest)
  }
  @Output() confirmRegister = new EventEmitter<boolean>();


  clickCancel(){
    this.matDialogRef.close();
  }

  submit(){
    if(this.model.invalid)
    {
      this.alertService.show("Erro", "Preencha todos os campos obrigatórios para continuar", AlertType.error);
      return;
    }
    if(this.isLoading) return;

    this.isLoading = true;

    this.addressRequest.addressName = this.model.get('name').value;
    this.addressRequest.isDefault = this.model.get('isDefault').value;

    if(this.isUpdate) {
      this.updateAddress();
    }
    else{
      this.createAddress();
    } 
  }

  populateData(){
    let completeAddress = `${this.address.street}, ${this.address.number}, ${this.address.neighborhood}, ${this.address.city}, ${this.address.state}, ${this.address.country}, ${this.address.zipcode}`;

    this.populatedAddress = completeAddress;
    this.model.get("name").setValue(this.address.addressName);
    this.model.get("isDefault").setValue(this.address.isDefault);
    if (this.address.isDefault == true) this.model.get('isDefault').disable();
  }


  createAddress(){
    this.branchDeliveryAdressService.post(this.addressRequest).subscribe({
      next:(response: CreateBranchDeliveryAdressResponse) => {
        if(response.isError == true){
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show("Sucesso", "Endereço cadastrado com sucesso", AlertType.success);
        this.confirmRegister.emit(true)
        this.matDialogRef.close({address: response.branchDeliveryAddress});
      },
      error:(error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  updateAddress(){
    this.addressRequest.idBranchDeliveryAdress = this.idBranchDeliveryAddress;
    this.addressRequest.latitude = this.address.latitude;
    this.addressRequest.longitude = this.address.longitude;
    this.branchDeliveryAdressService.put(this.addressRequest).subscribe({
      next:(response: ReturnStruct) => {
        if(response.isError == true){
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show("Sucesso", "Endereço atualizado com sucesso", AlertType.success);
        this.confirmRegister.emit(true)
        this.matDialogRef.close();
      },
      error:(error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
