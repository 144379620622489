import { Component, OnDestroy, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, FormGroup, FormBuilder} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { ModalUserDeleteComponent } from './modal-user-delete/modal-user-delete.component';
import { ModalUserEditComponent } from './modal-user-edit/modal-user-edit.component';
import { ListUserService } from 'src/app/shared/services/API/services/user/list-user.service';
import { listUserResponse } from 'src/app/shared/services/API/responses/user/list-user.response';
import { UserRequest } from 'src/app/shared/services/API/requests/user/user.request';
import { getUserResponse } from 'src/app/shared/services/API/responses/user/get-user.response.';
import { UserService } from 'src/app/shared/services/API/services/user/user.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { SearchUserService } from 'src/app/shared/services/API/services/user/search-user.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  constructor(private dialogRef: MatDialog,
    private listUserService: ListUserService,
    private userService: UserService,
    private searchUserService: SearchUserService,
    private alertService:AlertService,
    private location: Location,
    private formBuilder: FormBuilder,){

      this.model = this.formBuilder.group({
        searchText: [''],
      });
      this.onResize();
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public FormFieldModuel: MatFormFieldModule;
  public FormsModule: FormsModule;
  public MatInputModule: MatInputModule;
  public MatRadioModule: MatRadioModule;
  public MatCheckboxModule: MatCheckboxModule;
  public listUserResponse: listUserResponse;
  public isLoading: boolean;
  public model: FormGroup;
  public screenWidth: number;
  
  ngOnInit(){
    this.populatePage();
    this.menuModuleEnum = parseInt(sessionStorage.getItem("module"));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  backPage(){
    this.location.back();
  }

  populatePage(){
    if (this.listUserService) {
      this.listUserService.get().subscribe({
        next: (listUserService: listUserResponse) =>{
          if(listUserService.isError){
            this.alertService.show('Erro', listUserService.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
            this.listUserResponse = listUserService;
            this.listUserResponse.listUser
        }
      });
    } else {
    }
  }


  openDialogEdit(item: any): void{
    const dialogRef = this.dialogRef.open(ModalUserEditComponent,{
      height: '70%',
      width: '80%',
      data: {
        userName: item.userName,
        cpf: item.cpf,
        phone: item.phone,
        email: item.email,
        password: item.password,
        job: item.job,
      },
    });

    dialogRef.componentInstance.confirmRegister.subscribe((formData: any) => {
      const userRequest: UserRequest = new UserRequest();
      userRequest.userName = formData.userName;
      userRequest.cpf = formData.cpf;
      userRequest.email = formData.email;
      userRequest.phone = formData.phone;
      userRequest.password = formData.password;
      userRequest.job = formData.job;
      userRequest.listIdProfile = [];
  
      const idUser: number = item.idUser;
        
      this.userService.Put(userRequest, idUser).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            this.isLoading = false;
            return;
          }
          this.populatePage();
          this.alertService.show('Sucesso', 'Usuário editado', AlertType.success);
          this.dialogRef.closeAll();
        },
        error: (error) => {
          console.error(error);
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
        }
      });
    });

  }

  openDialogNew(){
    const dialogRef = this.dialogRef.open(ModalUserEditComponent,{
      height: 'auto',
      width: '80%',
    });

    dialogRef.componentInstance.confirmRegister.subscribe((formData: any) => {
      const userRequest: UserRequest = new UserRequest();
      userRequest.userName = formData.userName;
      userRequest.cpf = formData.cpf;
      userRequest.email = formData.email;
      userRequest.phone = formData.phone;
      userRequest.password = formData.password;
      userRequest.job = formData.job;
      userRequest.listIdProfile = [];

      this.userService.Post(userRequest).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Sucesso', 'Usuário cadastrado', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    });
  }
  
  openDialogDelete(item: any): void{
    const dialogRef = this.dialogRef.open(ModalUserDeleteComponent)
    const idUser: number = item.idUser;

    dialogRef.componentInstance.deleteConfirm.subscribe(() => {
      this.userService.Delete(idUser).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Excluído', 'Usuário excluído', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    });
  }


  searchUser(){
    const searchText = this.model.get('searchText').value;
    console.log(searchText)
    this.searchUserService.get(searchText).subscribe({
      next: (searchUserService: listUserResponse) => {
        if (searchUserService && searchUserService.isError) {
          if (this.alertService) {
            this.alertService.show('Erro', searchUserService.errorDescription, AlertType.error);
          }
          return;
        }
        this.listUserResponse = searchUserService;

        this.listUserResponse.listUser
      },
      error: (error) => {
        console.log(error);
        if (this.alertService) {
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
        this.isLoading = false;
      }
    });
  }
}
