export const environment = {
    production: false,
    staging:true,
    googleApiKey:"AIzaSyCeHsUbMu7P7n5D6ymzRvLQGmsYbVtp_xY",
    urlApiUser:'https://api.clusterstaging.meros.com.br/meros-api-user/',
    urlApiVehicle:'https://api.clusterstaging.meros.com.br/meros-api-vehicle/',
    urlApiPurchase:'https://api.clusterstaging.meros.com.br/meros-api-purchase/',
    urlApiServiceOrder:'https://api.clusterstaging.meros.com.br/meros-api-service-order/',
    urlApiOrchestratorPurchase:'https://api.clusterstaging.meros.com.br/meros-api-orchestrator-purchase/',
    urlApiAutoParts:'https://api.clusterdev.meros.com.br/meros-api-autoparts/',
  };